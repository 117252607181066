
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Didact Gothic', sans-serif;
}
  
:root {

  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);


  /* 385084 */

  --secondary: #00143d;
  --primary: #717a93;
  /* --primary: #ffffff; */
  --tertiary: #fefdfe;

  
}
 

body {
  margin: 0;
  padding: 0;
  background: var(--primary);
  color: var(--tertiary);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-family: 'Lucida Sans', sans-serif; */
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  color: var(--tertiary);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.header {
  background: var(--secondary);
  /* max-width: 1100px; */
  width: 100%;
  padding: 10px;
  padding-bottom: 0px;
  padding-top: 40px;
  position: fixed
}

.header-items {
  max-width: 1100px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}

.header a {
  color: var(--tertiary);
  text-decoration: none;
}

.main-view {
  min-height: 95vh;
}


.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--secondary);
  margin: 0 auto;
  height: 75px;
}

.footer a {
  margin: 10px;
}


button {
  font-size: 1rem;
  text-decoration: none;
  padding: 0.25rem 1rem;
  border-radius: .5rem;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
}

button:hover {
  opacity: .8;
}

button[disabled] {
  cursor: default;
  opacity: .8;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 40%;
}

input, textarea {
  font-size: 1rem;
  padding: 0.25rem;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus, textarea:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 .25rem rgba(0, 0, 0, .5);
}

.nav-item {
  font-size: 1.1em;
  width: 150px;
}

nav a:hover {
  text-decoration: none;
}

nav li {
  cursor: pointer;
  padding: 4px;
  border-radius: 10px;
  list-style: none;
  color: var(--light2);
  width: 90px;
  text-align: center;
}

nav li:hover {
  font-weight: 600;
}

nav {
  display: flex;
  align-items: flex-end;
  padding: 10px;
}

.title {
  margin-left: 20px;
}


.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
}

.page-header {
  margin-top: 125px;
  text-align: center;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.align-end {
  align-content: flex-end;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

section {
  /* min-height: 75vh; */
}

.card {
  display: flex;
  flex-direction: column;
  background: var(--secondary);
  padding: 2px;
  margin: 0 auto;
  margin: 10px;
  justify-content: center;
}

.project-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project {
  display: flex;
  flex-direction: column;
  background: var(--secondary);
  padding: 10px;
  margin: 10px;
  flex: 0 0 45%;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 20px;
}

.project-title {
  width: 100%;
  text-align: left;
  margin-left: 20px;
  color: var(--tertiary);
}

.project-image {
  width: 90%;
  margin: 0 auto;
}

.project .link-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.project .link-wrapper a {
  width: 75px;
  color: var(--tertiary);
  font-size: 14px;
  margin: 20px;
  padding: 7px;
  text-align: center;
  border-radius: 10px;
  border: var(--tertiary) 1px solid;
  text-decoration: none;
}

.project .link-wrapper a:hover {
  color: var(--secondary);
  background: var(--tertiary);
}

.project-text {
  color: var(--tertiary);
  font-size: 12px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5px;
}

.card-title {
  text-align: left;
  color: var(--tertiary);
  margin-left: 20px;
}

.card-header {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.card-frame {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.card-main {
  display: flex;
  padding: 5px;
}

.card-frame img {
  width: 90%;
  margin: 0 auto;
}

.card-frame ul {
  margin-left: 20px;
  list-style: none;
  line-height: 1.5;
}

.card-frame h5 {
  margin-left: 37px;
  text-align: left;
}
.card-body {
  flex: 8;
}

.card ul {
  margin-bottom: 20px;
}

.card li {
  margin-left: 20px;
}

.card-text {
  line-height: 1.5;
}


.img-thumbnail {
  width: auto;
  height: 150px;
  cursor: pointer;
}


.modalBackdrop {
	background: rgba(0, 0, 0, .65);
	bottom: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1;
}

.modalContainer {
	background: var(--tertiary);
	border-radius: 5px;
	max-width: 100%;
	margin: 50px auto;
	padding: 15px;
	width: 960px;
}

.modalTitle {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
}


.gallery-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.gallery-links a {
  color: var(--tertiary);
}

.navActive {
  background: var(--tertiary);
  color: var(--secondary); 
  font-weight: 800;
 
}

#contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-textInput {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.form-textArea {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#contact-form button {
  width: 75px;
  color: var(--tertiary);
  background: var(--secondary);
  font-size: 14px;
  padding: 7px;
  text-align: center;
  border-radius: 10px;
  border: var(--tertiary) 1px solid;
	cursor: pointer;
}

#contact-form button:hover {
  background-color: var(--tertiary);
  color: var(--secondary);
}

.contact {
  width: 400px;
  background: var(--secondary);
  margin: 0 auto;
  padding: 20px;
  color: var(--tertiary);
  font-size: 12px;
  border-radius: 20px;
}

.resume-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--secondary);
  padding: 20px;
  border-radius: 20px;
}

.resume-wrapper a {
  width: 100px;
  color: var(--tertiary);
  font-size: 14px;
  margin-top: 15px;
  padding: 7px;
  text-align: center;
  border-radius: 10px;
  border: var(--tertiary) 1px solid;
  text-decoration: none;
}

.resume-wrapper a:hover {
  color: var(--secondary);
  background: var(--tertiary);
}

.proficiencies-list {
  color: var(--tertiary);
  list-style: none;
}

.proficiencies-list li {
  margin-left: 20px;
  line-height: 1.5;
  font-size: 1em;
  font-style: italic;
}

.list-columns {
  display: flex;
  justify-content: space-evenly;
  color: var(--tertiary);
  width: 90%;
}

.list-columns img {
  margin: 0 auto;
}

.list-columns .left {
  flex: 1;
}

.list-columns .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bio-card {
  border-radius: 20px;
}

@media screen and (max-width: 650px) {
  .card-main {
    flex-direction: column;
  }

  .project {
    flex: 0 0 90%;
  }
  
  .contact {
    width: 95%;
  }

  .list-columns {
    flex-direction: column-reverse;
  }

  .header {
    height: auto;
    position: relative;
    width: 100%;
  }

  .page-header {
    margin: 10px;
  }
}
